import { StyleBreakpoints } from '@constants';
import ISOlang from 'iso-639-1';
import moment from 'moment';
import React from 'react';
import localization from '../localization';

const SMALL_SQUARE_FORMAT = 156; // 1:1, 640 x 640
const SMALL_RECT_FORMAT = 92; // 16:9, 686 x 386
const MEDIUM_RECT_FORMAT = 84; // 16:9, 1200 x 675
const LARGE_RECT_FORMAT = 88; // 16:9, 2400 x 1350
const MEDIUM_RECT_FORMAT_HQ = 148; // 17:9, 1200 x 630 higher quality

const FORMAT_CHANGE_BREAKPOINT_S = StyleBreakpoints.xs;
const FORMAT_CHANGE_BREAKPOINT_M = StyleBreakpoints.sm;

export const wrapedSynthetichandler = (handler) => (e) => handler(e.target.value);
export const formatPrice = (price) => (+price).toFixed(2);
export const formatDuration = (duration, unit) => `${duration} ${unit}${duration !== 1 ? 's' : ''}`;
export const formatDateToDMY = (date) => moment(date).format('DD/MM/YYYY');
export const formatDate = (date) => moment(date).format('ddd Do MMM YYYY');
export const formatDateTime = (date) => moment(date).format('ddd Do MMM YYYY hh:mm A');
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validEmail = (email) => emailRegex.test(email);
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
export const isObjectEmptyNullOrUndefined = (object) => {
  for (const key in object) {
    if (object[key] !== null || object[key] !== undefined) {
      return false;
    }
  }
  return true;
};
export const currencies = {
  eur: '€',
  usd: 'USD $',
  gbp: '£',
  aed: 'AED',
  inr: '₹',
  rsd: 'RSD ',
  kwd: 'KWD ',
  sar: 'SAR ',
  cad: 'CAD $',
  aud: 'AUD $',
  bdt: 'BDT ৳',
  bhd: 'BHD .د.ب',
  chf: 'CHF Fr.',
  kwd: 'KWD د.ك',
  lkr: 'LKR ரூ',
  myr: 'MYR RM',
  omr: 'OMR ر.ع',
  qar: 'QAR ر.ق',
  sar: 'SAR ﷼',
  sgd: 'SGD $',
  thb: 'THB ฿',
};

export const currencySign = (symbol) => {
  return currencies[symbol.toLowerCase()] ?? '';
};

export const getCurrency = () => {
  if (localStorage.getItem('curr')) {
    return localStorage.getItem('curr').substring(0, 3).toUpperCase();
  } else {
    if (window.location.href.indexOf('travelwings') > -1) {
      localStorage.setItem('curr', 'AED');
      return 'AED';
    } else {
      localStorage.setItem('curr', 'EUR €');
      return 'EUR €';
    }
  }
};

export const formatIfInr = (cost) => {
  if (getCurrency().toLocaleLowerCase() === 'inr') {
    return cost;
  }

  return cost;
};

export const getDurationDescriptionString = ({ days, hours, minutes }) => {
  return `
    ${
      days
        ? `${days} ${days > 1 ? localization.tours.duration.days : localization.tours.duration.day}`
        : ''
    } 
    ${
      hours
        ? `${hours} ${
            hours > 1 ? localization.tours.duration.hours : localization.tours.duration.hour
          }`
        : ''
    }
    ${
      minutes
        ? `${minutes} ${
            minutes > 1 ? localization.tours.duration.minutes : localization.tours.duration.minute
          }`
        : ''
    } 
  `;
};

export const getTourDuration = (duration) => {
  if (!duration) return null;

  if (duration?.unstructuredDuration === 'Flexible') return localization.tours.flexible;

  const { min, max } = duration;

  if (!min && !max) return null;

  if (JSON.stringify(min) === JSON.stringify(max) || (min && !max)) {
    return `${getDurationDescriptionString(min)} ${localization.tours.approx}`;
  }

  return `${localization.tours.from} ${getDurationDescriptionString(min)} ${
    localization.tours.to
  } ${getDurationDescriptionString(max)} ${localization.tours.approx}`;
};

const langNames = {
  cmn: 'Mandarin',
  es: 'Spanish',
  en: 'English',
  hi: 'Hindi',
  ar: 'Arabic',
  pt: 'Portuguese',
  bn: 'Bengali',
  ru: 'Russian',
  ja: 'Japanese',
  pa: 'Panjabi',
  de: 'German',
  jv: 'Javanese',
  wuu: 'Wu Chinese',
  ms: 'Malay',
  te: 'Telugu',
  vi: 'Vietnamese',
  ko: 'Korean',
  fr: 'French',
  mr: 'Marathi',
  ta: 'Tamil',
  ur: 'Urdu',
  tr: 'Turkish',
  it: 'Italian',
  yue: 'Cantonese',
  th: 'Thai',
  gu: 'Gujarati',
  fa: 'Farsi',
  pl: 'Polish',
  ps: 'Pushto',
  hsn: 'Hunanese',
  ml: 'Malayalam',
  my: 'Burmese',
  hak: 'Hakka Chinese',
  uk: 'Ukrainian',
  fil: 'Filipino',
  ro: 'Romanian',
  nl: 'Dutch',
  ku: 'Kurdish',
  hr: 'Croatian',
  hu: 'Hungarian',
  el: 'Greek',
  cs: 'Czech',
  sv: 'Swedish',
  fi: 'Finnish',
  sk: 'Slovak',
  sr: 'Serbian',
  no: 'Norwegian',
  he: 'Hebrew',
  da: 'Danish',
  zh_TW: 'Chinese Traditional',
  zh_CN: 'Chinese Simplified',
  id: 'Indonesian',
  mnp: 'Chinese Min Bei',
};
export function getLangName(langCode) {
  if (langNames[langCode]) {
    return langNames[langCode];
  } else {
    return ISOlang.getName(langCode);
  }
}

export const getCurrencySymbol = () => {
  return currencySign(getCurrency());
};

export function strToAbsoluteDate(datestr) {
  const date = new Date();
  const [y, m, d] = datestr.split('T')[0].split('-');
  return new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
}

export function getPriceWithCurrencySymbol(price, fixedCurrency = null) {
  const currency = fixedCurrency ?? getCurrency();
  const currencySymbol = currencySign(currency);

  const isFrenchLang = localization.getLanguage() === 'fr';
  const isDin = currencySymbol === 'RSD ';
  const isAED = currencySymbol === 'AED';
  const isINR = currencySymbol === '₹';
  const shouldSignBeAtRight = isFrenchLang || isAED;
  const fontSize = isAED || isDin ? '.6em' : 'inherit';

  if (shouldSignBeAtRight) {
    return (
      <span>
        {price}
        <span style={{ fontSize: fontSize }}>{currencySymbol}</span>
      </span>
    );
  } else {
    if (isINR) {
      if (typeof price == 'string') {
        return (
          <span>
            {currencySymbol}
            {price.split('.')[0]}
          </span>
        );
      } else {
        return (
          <span>
            {currencySymbol}
            {price}
          </span>
        );
      }
    } else {
      return (
        <span>
          <span style={{ fontSize: fontSize }}>{currencySymbol}</span>
          {price}
        </span>
      );
    }
  }
}

export function getFormattedTime(date) {
  const isFrenchLang = localization.getLanguage() === 'fr';
  if (isFrenchLang) {
    return moment(date).format('HH:mm');
  } else {
    return moment(date).format('h:mm\u00A0A');
  }
}

export function getFormattedDate(date) {
  const isFrenchLang = localization.getLanguage() === 'fr';
  if (isFrenchLang) {
    return moment(date).format('D MMMM YYYY');
  } else {
    return formatDate(date);
  }
}

export function getParticipantsTextShort(participant) {
  if (participant.priceType === 'pax') {
    participant.title =
      participant.title[0].toUpperCase() + participant.title.substring(1).toLowerCase();
    return `${participant.title} x ${participant.count}`;
  } else if (participant.priceType === 'flat') {
    return `${localization.common.groupOf} ${participant.count} - ${participant.perPerson} ${localization.common.each}`;
  } else {
    return participant.textRepresentation;
  }
}

export function getParticipantsTextLong(participant) {
  if (participant.priceType === 'pax') {
    return `${getParticipantsTextShort(participant)} ${localization.common.each}`;
  } else {
    return getParticipantsTextShort(participant);
  }
}

export function getCreditCardType(number) {
  var re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)/,
    dankort: /^(5019)/,
    interpayment: /^(636)/,
    unionpay: /^(62|88)/,
    visa: /^4[0-9]/,
    mastercard: /^5[1-5][0-9]/,
    amex: /^3[47][0-9]/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]/,
    discover: /^6(?:011|5[0-9]{2})[0-9]/,
    jcb: /^(?:2131|1800|35\d{3})/,
  };

  for (var key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
}

export function isiOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isGAIdValid(id) {
  return /^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/i.test(id);
}

export function isGTMIdValid(id) {
  return /^GTM-[A-Z0-9]{1,7}$/.test(id);
}

export function updateCountdownData(id, secondsLeft) {
  const currentCountdownData = JSON.parse(localStorage.getItem('countdown'));
  const newCountdownData = Object.fromEntries(
    Object.entries(currentCountdownData || {}).filter(
      (elem) => new Date(elem[1].countdownExpireAt) > new Date()
    )
  );
  const expireAtDate = new Date();
  expireAtDate.setSeconds(expireAtDate.getSeconds() + secondsLeft);
  newCountdownData[id] = {
    countdownExpireAt: expireAtDate,
  };
  localStorage.setItem('countdown', JSON.stringify(newCountdownData));
}

export function optimizeLocationImage(imageLink) {
  //NOTE for viator we canno longer do this
  return imageLink;
  const screenWidth = window.innerWidth;
  const isScreenSmall = screenWidth < FORMAT_CHANGE_BREAKPOINT_S;
  const isScreenMedium =
    screenWidth >= FORMAT_CHANGE_BREAKPOINT_S && screenWidth <= FORMAT_CHANGE_BREAKPOINT_M;

  if (isScreenSmall) {
    return changeFormatToSmallSquare(imageLink);
  }
  if (isScreenMedium) {
    return changeFormatToMediumRect(imageLink);
  }
  return changeFormatToLargeRect(imageLink);
}

export function optimizeTourImages(imageLink) {
  return imageLink;
  const screenWidth = window.innerWidth;
  const isScreenSmall = screenWidth < FORMAT_CHANGE_BREAKPOINT_S;

  if (isScreenSmall) {
    return changeFormatToSmallSquare(imageLink);
  }
  return changeFormatToMediumRectHq(imageLink);
}

export function optimizeImageForCheckoutPage(imageLink) {
  return imageLink;
  return changeImageFormat(imageLink, MEDIUM_RECT_FORMAT_HQ);
}

export function changeFormatToLargeRect(imageLink) {
  return imageLink;
  return changeImageFormat(imageLink, LARGE_RECT_FORMAT);
}

export function changeFormatToMediumRectHq(imageLink) {
  return imageLink;
  return changeImageFormat(imageLink, MEDIUM_RECT_FORMAT_HQ);
}

export function changeFormatToMediumRect(imageLink) {
  return imageLink;
  return changeImageFormat(imageLink, MEDIUM_RECT_FORMAT);
}

export function changeFormatToSmallRect(imageLink) {
  return imageLink;
  return changeImageFormat(imageLink, SMALL_RECT_FORMAT);
}

export function changeFormatToSmallSquare(imageLink) {
  return imageLink;
  return changeImageFormat(imageLink, SMALL_SQUARE_FORMAT);
}

export function changeImageFormat(imageLink, format) {
  if (!imageLink) {
    return '';
  }
  const ending = imageLink.match(/\/\d{1,3}\.jpg$|\/\d{1,3}\.jpeg$/);
  if (ending) {
    let endingWithCorrectFormat = ending[0].replace(/\d{1,3}/, `${format}`);
    return imageLink.replace(ending[0], endingWithCorrectFormat);
  }
  return imageLink;
}
