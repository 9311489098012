import { StyleBreakpoints } from '@constants';
import { isBookNowButton } from '@experimentConstants';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { ReactComponent as Arrow } from '../../assets/img/Arrow.svg';
import localization from '../../localization';
import { Analytics, URGENCY_MESSAGES_DATA_KEY } from '../../utils/analytics';
import { FontSizes } from '../../utils/constants';
import { formatPrice, getPriceWithCurrencySymbol } from '../../utils/helpers';
import ActivityRating from '../ActivityRating';
import ClockIcon from './../../assets/img/clock_icon.svg';
import './CardBase.scss';

const TourTitle = styled.span`
  font-size: ${FontSizes.cta};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: ${(props) => props.theme.fonts.bold};
  letter-spacing: -0.03px;
  text-align: justify;
  color: #000;
`;

const useStyles = makeStyles((theme) => ({
  bookNowButton: {
    borderRadius: '50%',
    minHeight: '18px',
    minWidth: '18px',
    height: '18px',
    width: '18px',
    fontWeight: 'bold',
    backgroundColor: `${theme.palette.searchBanner || '#6997ff'}`,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bookNowFooter: {
    display: 'flex',
    borderTop: 'solid 1px #e0e0e0',
    paddingTop: '12px',
    marginTop: '10px',
    justifyContent: 'center',
  },
  bookNowText: {
    flex: 1,
    fontSize: `${FontSizes.cta}`,
    fontWeight: 'bold',
  },
  root: {
    minWidth: '270px',
    width: '100%',
    borderRadius: '7px',
    textAlign: 'left',
  },
  rootMobile: {
    borderRadius: '7px',
    textAlign: 'left',
  },
  media: {
    height: 180,
  },
  mediaMobile: {
    height: 80,
  },
  mediaMobileList: {
    height: 180,
  },
  title: {
    textAlign: 'left',
    lineHeight: '13px',
    fontSize: FontSizes.cta,
    fontWeight: 'bold',
  },
  titleMobile: {
    textAlign: 'left',
    lineHeight: '9px',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  tag: {
    backgroundColor: '#04112d',
    fontFamily: 'MontserratBold',
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '11px',
    borderRadius: '4.4px',
    padding: '2px 0.5rem',
    color: '#fff',
    marginBottom: '0.5rem',
    marginRight: '4px',
    height: 'fit-content',
    lineHeight: '20px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.5)',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      fontSize: '12px',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 184,
    padding: '2px 16px 12px 16px',
  },
  cardContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 146,
    padding: '4px 16px 16px 16px',
  },
  cardBody: {
    flex: '1 0 auto',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardActionsFlags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  },
  locationTitle: {
    padding: '8px 0 4px 0',
    fontSize: '12px',
    color: '#63666a',
    fontFamily: theme.typography.fontFamily,
  },
  urgencyMessage: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#fe424d',
    margin: '10px 0',
  },
  clockIcon: {
    maxHeight: '15px',
    margin: '0 7px 2px 0',
  },
  arrowIcon: {
    fill: 'white',
    height: '8px',
  },
}));

const pricingType = {
  PER_PERSON: localization.common['per person'],
  GROUP: localization.common['per group'],
};

function TourCard({ item, appendHeadlessQueryParam, isClickable, inCarousel, inList }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tourLocation, setTourLocation] = useState();
  const [bookingsAmount, setBookingAmount] = useState(0);

  const classes = useStyles({});
  useEffect(() => {
    if (!item) return;

    const urgencyMessagesData = JSON.parse(localStorage.getItem(URGENCY_MESSAGES_DATA_KEY));

    if (isNil(urgencyMessagesData.cache.tours[item.TourID])) {
      const shouldHaveAMessage = item.Flags.likelyToSellOut && Math.random() >= 0.75;
      const newBookingsAmount = shouldHaveAMessage
        ? Math.floor(Math.random() * (30 - 5 + 1) + 5)
        : 0;
      urgencyMessagesData.cache.tours[item.TourID] = newBookingsAmount;
      localStorage.setItem(URGENCY_MESSAGES_DATA_KEY, JSON.stringify(urgencyMessagesData));
      setBookingAmount(newBookingsAmount);
    } else {
      setBookingAmount(urgencyMessagesData.cache.tours[item.TourID]);
    }
  }, [item]);

  useEffect(() => {
    item.tourLocation && setTourLocation(item.tourLocation.english_name);
  }, [item]);

  useEffect(
    function initTourDetailsBase() {
      const updateWidth = () => setScreenWidth(window.innerWidth);
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    },
    [screenWidth]
  );

  if (!item) {
    return null;
  }

  const { PublishedPrice } = item;

  function renderFlag(flagText, redVariant) {
    return (
      <div className={classes.tag} style={{ backgroundColor: redVariant ? '#fe424d' : '' }}>
        {flagText}
      </div>
    );
  }

  const flagOrder = [
    { key: 'uniqueExperience', text: localization.tours.flags.uniqueExperience },
    { key: 'topRated', text: localization.tours.flags.topRated },
    { key: 'instant_confirmation', text: localization.tours.flags.instantConfirmation },
    { key: 'mobile_voucher', text: localization.tours.flags.mobileVoucher },
    { key: 'private', text: localization.tours.flags.private },
    { key: 'freeCancellation', text: localization.tours.flags.freeCancellation },
    { key: 'wheelchair', text: localization.tours.flags.wheelchair },
  ];

  function renderFlags(flags) {
    if (!flags) {
      return null;
    }

    let renderableFlags = [];

    for (var f = 0; f < flagOrder.length; f++) {
      let flagContent = flagOrder[f];
      if (flags[flagContent.key] && renderableFlags.length < 2) {
        renderableFlags.push(flagContent);
      }
    }
    return <>{renderableFlags.map((f) => renderFlag(f.text))}</>;
  }

  function renderTopFlags(flags) {
    //Render 1
    if (flags.likelyToSellOut) {
      return renderFlag(localization.tours.flags.likelyToSellOut, true);
    }
    return null;
  }

  var url = '/tour/' + item.TourID + '/' + item.Title.replace(/%|\//gi, '').split(' ').join('-');
  if (appendHeadlessQueryParam) {
    //url = "/headlesstour/" + item._id
  }
  let imageUrl = item.DisplayImageRemote.CarouselOne;
  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  let nonCarouselWidth = '100%';
  let carouselWidth = isMobile ? '220px' : '320px';

  let cardStyles = {
    width: inCarousel ? carouselWidth : nonCarouselWidth,
    height: inCarousel ? '100%' : 'auto',
  };
  let mediaStyles = isMobile ? classes.mediaMobile : classes.media;
  if (inList) {
    mediaStyles = isMobile ? classes.mediaMobileList : classes.media;
  }

  return (
    <Card className={isMobile ? classes.rootMobile : classes.root} style={cardStyles}>
      <CardActionArea
        classes={{ root: classes.cardRoot }}
        onClick={() => {
          openInNewTab(url);
        }}
      >
        <CardMedia classes={{ root: mediaStyles }} image={imageUrl}>
          <div
            style={{
              padding: '10px',
            }}
          >
            {renderTopFlags(item.Flags)}
          </div>
        </CardMedia>
        <CardContent className={isMobile ? classes.cardContentMobile : classes.cardContent}>
          <div className={classes.cardBody}>
            <div className={classes.locationTitle}>{tourLocation}</div>
            <Typography
              className={isMobile ? classes.titleMobile : classes.title}
              gutterBottom
              variant="h5"
              component="h2"
            >
              <TourTitle>{item.Title}</TourTitle>
            </Typography>
            <ActivityRating tour={item} />
            {bookingsAmount ? (
              <p className={classes.urgencyMessage}>
                <img className={classes.clockIcon} src={ClockIcon} alt="Clock icon" />
                {bookingsAmount} {localization.common.urgencyMessage}
              </p>
            ) : null}
          </div>
          <div className={classes.cardActionsFlags}>{renderFlags(item.Flags)}</div>
          <div className={classes.cardActions}>
            <TourTitle>
              <span style={{ marginRight: 4 }}>{localization.tours.from}</span>
              <span style={{ fontSize: 20, marginRight: 4 }}>
                {getPriceWithCurrencySymbol(formatPrice(PublishedPrice))}
              </span>
              <span>
                {pricingType[item.pricingInfo?.type] ||
                  pricingType[item.pricingInfo?.unitType] ||
                  null}
              </span>
            </TourTitle>
          </div>
          {isBookNowButton() && (
            <div
              className={classes.bookNowFooter}
              onClick={() => Analytics.track('tour card book now button clicked')}
            >
              <div className={classes.bookNowText}>
                <TourTitle>{localization.common.bookNow}</TourTitle>
              </div>
              <div className={classes.bookNowButton}>
                <Arrow className={classes.arrowIcon} />
              </div>
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default TourCard;
