import makeStyles from '@mui/styles/makeStyles';
import { groupBy, isNil } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import localization from '../../../localization';
import { isCardedOptions, isExpandableOptions } from '../../../utils/experimentConstants';
import { Divider } from '../../Common';
import UrgencyCountdown from '../../Common/Countdown';
import TourOption from './BookingOption';

const useStyles = makeStyles(() => ({
  timerWrapper: {
    marginBottom: '30px',
    textAlign: 'right',
  },
}));

const ErrorWrapper = ({ errorText }) => {
  return (
    <>
      <div style={{ color: 'red' }}>{errorText}</div>
      <Divider style={{ width: '100%' }} />
    </>
  );
};

export default function BookingOptionList({
  bookingOptions,
  participants,
  language,
  updateSelectedTimeslot,
  isBookingInProgress,
  date,
  handleOptionSelect,
  extraTrackingProperties,
}) {
  const classes = useStyles();
  const [formattedBookingOptions, setFormattedBookingOptions] = useState([]);

  useEffect(() => {
    const bookingOptionsWithoutErrors = bookingOptions.filter((o) => o.tag !== 'error');
    formatTimeslots(bookingOptionsWithoutErrors);
  }, [bookingOptions]);

  const [timer, setTimer] = useState();

  function formatTimeslots(options) {
    let optionsArray = [];
    let activeTimeSlot;
    const sortedOptions = options.sort(
      (a, b) =>
        a.slotId?.localeCompare(b.slotId) || a.timeslots[0].time.hours - b.timeslots[0].time.hours
    );

    for (let i = 0; i < sortedOptions.length; i++) {
      if (!activeTimeSlot) {
        activeTimeSlot = { ...sortedOptions[i] };
        activeTimeSlot.timeslots = [activeTimeSlot.timeslots[0]];

        if (i === sortedOptions.length - 1) {
          optionsArray.push(activeTimeSlot);
          break;
        }
        continue;
      }

      if (sortedOptions[i].slotId === activeTimeSlot.slotId) {
        activeTimeSlot.timeslots.push(sortedOptions[i].timeslots[0]);
      } else {
        optionsArray.push(activeTimeSlot);
        activeTimeSlot = { ...sortedOptions[i] };
        activeTimeSlot.timeslots = [activeTimeSlot.timeslots[0]];
      }

      if (i === sortedOptions.length - 1) {
        optionsArray.push(activeTimeSlot);
      }
    }

    setFormattedBookingOptions(optionsArray);
  }

  function renderOptions(
    options,
    isOpenState,
    setIsOpenState,
    optionStartNumber = 0,
    hasOptionsWithoutLanguage = false,
    isOnlyOneOption = false
  ) {
    if (!options.length && isOnlyOneOption) {
      return null;
    }

    if (!options.length && hasOptionsWithoutLanguage) {
      return (
        <ErrorWrapper errorText={localization.tours.availabilitySearch.noAvailabilityForLanguage} />
      );
    }
    if (!options.length) {
      return (
        <ErrorWrapper errorText={localization.tours.availabilitySearch.noAvailabilityForDay} />
      );
    }

    function setIsOpen(option) {
      if (isExpandableOptions()) {
        setIsOpenState(
          Object.keys(isOpenState).reduce((acc, val) => {
            if (val === option.slotId || val === option.title) {
              acc[val] = !isOpenState[option.slotId || option.title];
            } else {
              acc[val] = false;
            }
            return acc;
          }, {})
        );
      }
    }

    return options.map((option, i) => (
      <TourOption
        key={option.slotId}
        number={optionStartNumber + i + 1}
        option={option}
        participants={participants}
        isBookingInProgress={isBookingInProgress}
        extraTrackingProperties={extraTrackingProperties}
        onTimeslotSelect={(timeslot) => {
          updateSelectedTimeslot(option.slotId, timeslot);
        }}
        date={date}
        handleOptionSelect={() => handleOptionSelect(option, timer)}
        productOptionDescription={option.productOptionDescription}
        description={option.description}
        isOpen={isExpandableOptions() ? isOpenState[option.slotId || option.title] : true}
        setIsOpen={() => setIsOpen(option)}
      />
    ));
  }

  const { true: withLanguage, false: withoutLanguage } = groupBy(
    (option) => isNil(language) || option.languages?.includes(language),
    formattedBookingOptions
  );
  const isOnlyOneOption = withoutLanguage?.length === 1 && !withoutLanguage[0]?.slotId;

  const allOptions = [...(withLanguage || []), ...(withoutLanguage || [])];

  const areOptionsPresent = withLanguage?.length || withoutLanguage?.length;

  const [isOpenState, setIsOpenState] = useState();

  useEffect(() => {
    if (formattedBookingOptions.length && !isOpenState) {
      setIsOpenState(
        [...(withLanguage || []), ...(withoutLanguage || [])].reduce((acc, val, i) => {
          acc[val.slotId || val.title] = i === 0;
          return acc;
        }, {})
      );
    }
  }, [language, formattedBookingOptions]);

  if (!isOpenState) {
    return null;
  }

  return (
    <>
      {!!allOptions.length && (
        <div className={classes.timerWrapper}>
          <UrgencyCountdown timer={timer} setTimer={setTimer} />
        </div>
      )}

      <div className="tour-options">
        {isCardedOptions() && areOptionsPresent && (
          <div className="second-heading">
            {localization.tours.availabilitySearch.availableOptions}:
          </div>
        )}
        {renderOptions(
          withLanguage || [],
          isOpenState,
          setIsOpenState,
          0,
          Boolean(withoutLanguage?.length),
          isOnlyOneOption
        )}
        {withoutLanguage && withoutLanguage.length > 0 && (
          <>
            {!isOnlyOneOption && (
              <>
                <p className="second-heading" style={{ padding: 0, margin: '-10px 0' }}>
                  {localization.tours.availabilitySearch.otherLanguageOptions}
                </p>
                <Divider style={{ width: '100%' }} />
              </>
            )}
            {renderOptions(withoutLanguage, isOpenState, setIsOpenState, withLanguage?.length)}
          </>
        )}
      </div>
    </>
  );
}
