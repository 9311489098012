import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import localization from '../../../localization';
import { FontSizes } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    marginRight: 0,
    marginLeft: 0,
  },
  sortedByText: {
    marginRight: 12,
  },
  selectFromOrders: {
    fontWeight: 'bold',
    fontFamily: 'MontserratBold',
    cursor: 'pointer',
  },
}));

const orderTitles = {
  featured: localization.location.sort.featuredTitle,
  rating: localization.location.sort.ratingTitle,
  plow: localization.location.sort.plowTitle,
  phigh: localization.location.sort.phighTitle,
  dlong: localization.location.sort.dlongTitle,
};

const orderValues = {
  featured: localization.location.sort.featuredValue,
  rating: localization.location.sort.ratingValue,
  plow: localization.location.sort.plowValue,
  phigh: localization.location.sort.phighValue,
  dlong: localization.location.sort.dlongValue,
};

export default function SortOrderSelect(props) {
  const classes = useStyles();
  const [sortOrder, setSortOrder] = useState('featured');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (value) => {
    props.handleSortOrderSelection(value);
    setAnchorEl(null);
    setSortOrder(value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FormControl className={classes.formControl}>
      <div style={{ fontSize: FontSizes.p, fontFamily: 'MontserratRegular', fontWeight: 'bold' }}>
        <span className={classes.sortedByText}>{localization.location.sort.sortedBy}</span>
        <span
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.selectFromOrders}
        >
          {orderTitles[sortOrder]} <ExpandMoreIcon />
        </span>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        style={{ top: 10 }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(orderValues).map(([key, value]) => (
          <MenuItem key={key} value={key} onClick={() => handleChange(key)}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
}
