import logo from '../assets/img/Saudia.png';
var banner = require('../assets/img/banners/Campsited2.jpg');
import logoWhite from '../assets/img/Saudia.png';
import rollingBanner1Web from '../assets/img/banners/jazeera/HDDesktop_WL_Home [1920x400].jpg';
import rollingBanner1Mob from '../assets/img/banners/jazeera/Phone_WL_Home [Banner1].jpg';
import rollingBanner1Tab from '../assets/img/banners/jazeera/iPad_WL_Home [Banner4].jpg';
import localization from '../localization';
var banner = require('../assets/img/banners/airserbia_banner.jpg');
var favicon = require('../assets/img/favicon.ico');

export default {
  name: 'Saudia',
  clientName: 'Saudia',
  config: {
    showMapsByDefault: false,
    GTMID: '',
    usePrimaryColoredBanner: false,
    dontUseWhiteLogoOnHomepage: false,
    customLogoHeight: '30px',
    contactUsLink: 'http://tripadmit.com/',
    supportLink: 'http://tripadmit.com/',
    aboutUsLink: 'http://tripadmit.com/',
    cookiePolicyLink: 'http://tripadmit.com/',
    privacyPolicyLink: 'http://tripadmit.com/',
    tosLink: 'http://tripadmit.com/',
    overrideActivityProviderText: 'Activity brought to you by Saudia',
  },
  meta: {
    title: 'TripAdmit - Tours and Activities',
    description: 'Connecting Experiences',
    home: '/home',
  },
  colors: {
    primary: '#1a73e8',
    secondary: '#517791',
    inactive: '#BCBCBC',
  },
  fonts: {
    colors: {
      primary: '#1a73e8',
      secondary: '#517791',
      inactive: '#BCBCBC',
    },
    primary: 'MontserratRegular',
    regular: 'MontserratRegular',
    light: 'MontserratRegular',
    bold: 'MontserratBold',
  },
  styles: {
    airserbiaButton: {
      borderRadius: '4px!important',
    },
    pickerControlButton: {
      background: '#1a73e8',
      borderRadius: '4px',
      width: '32px',
      height: '32px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
    },
    pickerCounter: {
      background: 'none',
      border: '0',
      width: '36px',
      height: '32px',
      padding: '0',
      textAlign: 'center',
      margin: '0 2px',
    },
  },
  bannerImages: {
    images: [
      {
        web: rollingBanner1Web,
        mob: rollingBanner1Mob,
        tab: rollingBanner1Tab,
        text: localization.rollingBanner.static,
        link: '',
      },
    ],
  },

  assets: {
    logo,
    logoWhite,
    favicon,
    banner,
  },
  currency: {
    default: 'EUR',
    defaultWithSymbol: 'EUR €',
    available: {
      EUR: 'EUR €',
      SAR: 'SAR ﷼',
      AED: 'AED د.إ',
      USD: 'USD $',
      GBP: 'GBP £',
    },
  },
  language: {
    default: 'en',
    available: ['en'],
  },
};
